// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Buttons
@include bg-variant(".bg-sc-color-a", hsl(203, 100%, 12%));
@include bg-variant(".bg-sc-color-b", hsl(198, 100%, 44%));
@include bg-variant(".bg-sc-color-c", hsl(65, 100%, 42%));
@include bg-variant(".bg-sc-color-d", hsl(31, 100%, 50%));

.btn-primary {
    @include button-variant($sc-primary-background, $sc-primary-border, $sc-primary-hover-background, $sc-primary-hover-border);
}

.btn-success {
    @include button-variant($sc-success-background, $sc-success-border, $sc-success-hover-background, $sc-success-hover-border);
}

.img-navbar-brand {
	height: 39px;
}

.image-login {
	margin-top: 30px;
	margin-bottom: 20px;
}

.checkbox-login {
	margin-top: 0rem;
}

.body-login {
	background-color: #ededed !important;
	line-height: 1;
}

.p-starchapter-api {
	font-family: "Open Sans", sans-serif;
	font-size: 28px;
	color: #333;
	font-weight: bold;
}

.p-login {
	font-family: "Open Sans", sans-serif;
	font-size: 28px;
	color: #333;
	font-weight: 100;
}

.a-forgot-password {
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	color: #009bde;
	font-weight: bold;
}

.button-login {
	background-color: #265477;
	border: 1px solid #265477;
	padding: 5px 10px;
	font-size: 12px;
}

.underline-input {
    background: none;
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    -webkit-appearance: none !important;
    vertical-align: middle;
    padding-left: 8px;
    border-color: #a6a6a6;
    font-size: 12px;

    &:focus {
    	box-shadow: none !important;
    	border-color: #009bde;
    }

	&::placeholder {
		font-style: italic;
	}
}